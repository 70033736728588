import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeSolutions = () => {
    return (
        <section data-scrollax-parent="true" id="highlights">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>highlights </div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>OUR HIGHLIGHTS</h3>
                    <h2>COGNIZANT <span> HIGHLIGHTS</span></h2>
          
                </div>
                <div class="process-wrap fl-wrap"><div class="row"><ul><li><div class="time-line-icon"><i class="fab pragicts-gear-icon"></i></div><h4>100% Customizable</h4><div class="process-details"><p>Tailored to your exact needs</p></div><span class="process-numder">01.</span></li><li><div class="time-line-icon"><i class="fal pragicts-web-icon"></i></div><h4>100% Web Browser Based</h4><div class="process-details"><p>No client end software is required.</p>                            </div><span class="process-numder">02.</span></li><li><div class="time-line-icon"><i class="fal pragicts-resp-icon"></i></div><h4>100% Responsive</h4><div class="process-details"><p>Run it on any device (phone, tablet, laptop, desktop, HDTV,etc.)</p></div><span class="process-numder">03.</span></li><li><div class="time-line-icon"><i class="fal pragicts-open-icon"></i></div><h4>100% Open Source</h4><div class="process-details"><p>Host it on any deployment configuration (on-premise, cloud, etc.)</p></div><span class="process-numder">04.</span></li><li><div class="time-line-icon"><i class="fal pragicts-live-icon"></i></div><h4>Live Dashboard</h4><div class="process-details"><p>For real-time tracking and corrective action.</p></div><span class="process-numder">05.</span></li><li><div class="time-line-icon"><i class="fal pragicts-secure-icon"></i></div><h4>Secure</h4><div class="process-details"><p>Multilayered decoupled abstracted security architecture including SSL encryption.</p></div><span class="process-numder">06.</span></li></ul></div></div>
            </div>
           
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeSolutions
